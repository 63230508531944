import axios from "axios";
import Helpers from "../utils/Helpers";
import TokenManager from "../utils/TokenManager";
import AlertManager from "../utils/AlertManager";

export default {
  request: function() {
    const headers = {
      "x-api-key": Helpers.getApiKey(),
      "Content-Type": "application/json"
    };
    // Set config defaults when creating the instance
    const instance = axios.create({
      baseURL: Helpers.getBaseURL(),
      headers: headers
    });
    // Apply 'Authorization' in header when token found!
    const token = TokenManager.getBosAdminToken();
    if (token !== "") {
      // Alter defaults after instance has been created
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    return instance;
  },
  requestFile: function() {
    const headers = {
      "x-api-key": Helpers.getApiKey(),
      "Content-Type": "application/x-www-form-urlencoded"
    };
    // Set config defaults when creating the instance
    const instance = axios.create({
      baseURL: Helpers.getBaseURL(),
      headers: headers
    });
    // Apply 'Authorization' in header when token found!
    const token = TokenManager.getBosAdminToken();
    if (token !== "") {
      // Alter defaults after instance has been created
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    return instance;
  },
  errorMessage: function(error) {
    switch (error.response.status) {
      case 401:
        AlertManager.showErrorDialogWithAction(
          error.response.status,
          "Unauthorized! Please login again",
          this.navigateToLoginPage
        );
        break;

      case 403:
        AlertManager.showErrorDialogWithAction(
          error.response.status,
          "Permission Denied! Please contact system administrator",
          this.navigateToHomePage
        );
        break;

      default:
        AlertManager.showTechnicalErrorDialog(error.response.status);
    }
    return error.response;
  },
  navigateToLoginPage: function() {
    localStorage.clear();
    location.href = process.env.BASE_URL + "login";
  },
  navigateToHomePage: function() {
    location.href = process.env.BASE_URL;
  }
};
