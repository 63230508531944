import MainConnection from "./MainConnection";
import AlertManager from "../utils/AlertManager";

export default {
  uploadNewFile: async function(from_data) {
    try {
      const requestURL = "/files/upload";
      const response = await MainConnection.requestFile().post(
        requestURL,
        from_data
      );

      switch (response.data.code) {
        case "0":
          return response.data;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          break;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  removeFileByName: async function(file_name) {
    try {
      const requestURL = "/files/delete/" + file_name;
      const response = await MainConnection.requestFile().delete(requestURL);

      switch (response.data.code) {
        case "0":
          return true;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          return false;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  }
};
