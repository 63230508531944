import dayjs from "dayjs";
import FileApi from "@/apis/FileApi";
import AuthenticationApi from "@/apis/AuthenticationApi";
import TokenManager from "@/utils/TokenManager";

export default {
  getBaseURL: function() {
    const hostURL = window.location.hostname;
    switch (hostURL) {
      case "admin.blushingowlstudio.com":
        // Production
        return "https://admin.blushingowlstudio.com/api";
      default:
        // Testbed
        return "https://admin.test.blushingowlstudio.com/api";
    }
  },
  getApiKey: function() {
    const hostURL = window.location.hostname;
    switch (hostURL) {
      case "admin.blushingowlstudio.com":
        // Production
        return "ADM-z9Cc7pwPZR2cLkm02I65c3GYnoUhiIE1";
      default:
        // Testbed
        return "ADM-z9Cc7pwPZR2cLkm02I65c3GYnoUhiIE1";
    }
  },
  dateFormatter: function(value) {
    if (!value) return "";
    return dayjs(value).format("DD/MM/YYYY");
  },
  dateFormatterWithTime: function(value) {
    if (!value) return "";
    return dayjs(value).format("DD/MM/YYYY @ HH:mm:ss");
  },
  uploadImage: async function(file) {
    const fd = new FormData();
    fd.append("file", file, file.name);
    const response = await FileApi.uploadNewFile(fd);
    return response;
  },
  convertUrlToImageName: function(input_url) {
    const splited_url = input_url.split("/");
    const file_name = splited_url[splited_url.length - 1];
    return file_name;
  },
  removeImage: async function(filename) {
    const response = await FileApi.removeFileByName(filename);
    return response;
  },
  performLogout: async function() {
    const response = await AuthenticationApi.requestLogout();
    TokenManager.clearAllTokens();
    return response;
  }
};
