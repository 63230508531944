import MainConnection from "./MainConnection";
import AlertManager from "../utils/AlertManager";
import TokenManager from "../utils/TokenManager";

export default {
  requestAuthentication: async function(params) {
    try {
      const requestURL = "/auth/login";
      const response = await MainConnection.request().post(requestURL, params);

      switch (response.data.code) {
        case "0":
          TokenManager.setBosAdminToken(response.data.data.token);
          return true;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          break;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestLogout: async function() {
    try {
      const requestURL = "/auth/logout";
      const response = await MainConnection.request().post(requestURL);
      switch (response.data.code) {
        case "0":
          return true;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          break;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  }
};
