import { DialogProgrammatic as Dialog } from "buefy";
import { SnackbarProgrammatic as Snackbar } from "buefy";

export default {
  showErrorDialog: function(code, message) {
    Dialog.alert({
      type: "is-warning",
      title: code + " Business Error",
      message,
      hasIcon: true
    });
  },
  showErrorDialogWithAction: function(code, message, callback) {
    Dialog.alert({
      type: "is-danger",
      title: code + " Error",
      message,
      hasIcon: true,
      onConfirm: callback
    });
  },
  showTechnicalErrorDialog: function(code) {
    Dialog.alert({
      type: "is-danger",
      title: code + " Internal Error",
      message: "An internal error occured, Please try again in a few minutes.",
      hasIcon: true
    });
  },
  showErrorSnackbar: function(message) {
    Snackbar.open({
      type: "is-danger",
      position: "is-bottom-right",
      message: "Error: " + message
    });
  },
  showSuccessSnackbar: function(message) {
    Snackbar.open({
      type: "is-success",
      position: "is-bottom-right",
      message
    });
  }
};
